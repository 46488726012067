import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiTextfieldControllerModule
} from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiInputModule,
  TuiSelectModule
} from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { FolderDto } from '../folder.service';

export interface EditFolderData {
  folder?: FolderDto;
  flatFolderList: FolderDto[];
}

export interface EditFolderResult {
  name: string;
  parentId?: number;
}

@Component({
  selector: 'knk-edit-folder',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    TuiButtonModule,
    TuiSelectModule,
    TuiDataListWrapperModule,
    TuiTextfieldControllerModule,
    TuiInputModule
  ],
  templateUrl: './edit-folder.component.html',
  styleUrls: ['./edit-folder.component.scss']
})
export class EditFolderComponent {
  private readonly context =
    inject<TuiDialogContext<EditFolderResult | null, EditFolderData>>(
      POLYMORPHEUS_CONTEXT
    );

  protected readonly flatFolderList = this.context.data.flatFolderList;

  protected readonly nameControl = new FormControl(
    this.context.data?.folder?.name ?? '',
    {
      nonNullable: true,
      validators: [Validators.required]
    }
  );

  protected readonly parentFolderControl = new FormControl(
    this.context.data.flatFolderList.find(
      (f) => f.id === this.context.data.folder?.parentId
    ) ?? null
  );

  cancel() {
    this.context.completeWith(null);
  }

  save() {
    this.context.completeWith({
      name: this.nameControl.value,
      parentId: this.parentFolderControl.value?.id
    });
  }
}
