<tui-input [formControl]="nameControl">
  {{ 'folders.editDialog.nameControlLabel' | translate }}
</tui-input>

<tui-select
  class="tui-space_top-2"
  [formControl]="parentFolderControl"
  [valueContent]="folderContent"
  [tuiTextfieldCleaner]="true"
>
  {{ 'folders.editDialog.parentFolderControlLabel' | translate }}
  <tui-data-list-wrapper *tuiDataList [items]="flatFolderList" [itemContent]="folderContent"></tui-data-list-wrapper>
</tui-select>

<ng-template #folderContent let-folder>
  {{ folder.name }}
</ng-template>

<div class="tui-space_top-2 bottom-controls">
  <button class="to-right" tuiButton appearance="secondary" (click)="cancel()">
    {{ 'common.cancel' | translate }}
  </button>
  <button class="tui-space_left-2" tuiButton [disabled]="nameControl.invalid" (click)="save()">
    {{ 'common.save' | translate }}
  </button>
</div>
