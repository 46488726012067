import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  MetastoreService,
  and,
  equal,
  extractObjectParams,
  from,
  icContains
} from '@konnektu/metastore';
import { TranslateService } from '@ngx-translate/core';
import { tuiIsPresent } from '@taiga-ui/cdk';
import { TuiAlertService, TuiDialogService } from '@taiga-ui/core';
import { TUI_PROMPT } from '@taiga-ui/kit';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { debounceTime, filter, map, mergeMap, switchMap } from 'rxjs';
import {
  EditFolderComponent,
  EditFolderResult
} from './edit-folder/edit-folder.component';

export interface FolderDto {
  name: string;
  filter: any;
  id: number;
  parentId?: number;
}

export interface ExpandedFolderDto extends FolderDto {
  parent?: FolderDto;
  children: ExpandedFolderDto[];
}

@Injectable()
export class FolderService {
  private readonly metastore = inject(MetastoreService);

  private readonly tuiDialog = inject(TuiDialogService);

  private readonly tuiAlert = inject(TuiAlertService);

  private readonly translate = inject(TranslateService);

  isLoading = signal(false);

  folders = signal<FolderDto[]>([]);

  entityName = signal<string>('User');

  additionalFilter = signal<object | null>(null);

  nameSearch = signal('');

  nameSearch$ = toObservable(this.nameSearch);

  folderQueryCondition = computed(() => {
    let condition: Record<string, any> = equal('EntityName', this.entityName());

    const additionalFilter = this.additionalFilter();
    if (additionalFilter) {
      condition = and(condition, additionalFilter);
    }

    return condition;
  });

  folderHierarchy = computed(() => {
    // TODO: multiple loops on every folder list change
    const findParent = (folders: FolderDto[], folder: FolderDto) =>
      folders.find((f) => f.id === folder.parentId);

    const collectChildren = (
      folders: FolderDto[],
      parent: FolderDto
    ): ExpandedFolderDto[] =>
      folders
        .filter((f) => f.parentId === parent.id)
        .map(
          (f) =>
            ({
              ...f,
              children: collectChildren(this.folders(), f),
              parent: findParent(this.folders(), f)
            }) as ExpandedFolderDto
        );

    return this.folders()
      .filter((f) => !f.parentId)
      .map(
        (folder) =>
          ({
            ...folder,
            children: collectChildren(this.folders(), folder),
            parent: findParent(this.folders(), folder)
          }) as ExpandedFolderDto
      );
  });

  constructor() {
    toObservable(this.folderQueryCondition)
      .pipe(
        switchMap((baseFolderCondition) =>
          this.nameSearch$.pipe(
            debounceTime(200),
            map((nameSearch) => {
              if (nameSearch) {
                return and(baseFolderCondition, icContains('Name', nameSearch));
              }
              return baseFolderCondition;
            })
          )
        ),
        switchMap((resultCondition) =>
          this.metastore.select<{
            Id: number;
            Name: string;
            Filter: string;
            EntityName: string;
            ParentId: number;
          }>(
            from('UIFolder')
              .select(['Id', 'Name', 'Filter', 'EntityName', 'ParentId'])
              .where(resultCondition)
              .done()
          )
        ),
        takeUntilDestroyed()
      )
      .subscribe((folders) =>
        this.folders.update(() =>
          folders.map((f) => {
            const parsed = JSON.parse(f.Filter);
            // TODO: for backward compatibility with older filter values
            // actual metastore query key should be "query"
            const backwardCompatible = {
              query: parsed.query ?? parsed.expression,
              parameters: parsed.parameters
            };
            return {
              name: f.Name,
              parentId: f.ParentId,
              id: f.Id,
              filter: backwardCompatible
            };
          })
        )
      );
  }

  saveFolder(filterObj: object) {
    const extracted = extractObjectParams(filterObj);
    this.tuiDialog
      .open<EditFolderResult | null>(
        new PolymorpheusComponent(EditFolderComponent),
        {
          data: {
            flatFolderList: this.folders()
          }
        }
      )
      .pipe(
        filter(tuiIsPresent),
        mergeMap(({ name, parentId }) =>
          this.metastore.insert<{ Id: number; Name: string; ParentId: number }>(
            'UIFolder',
            {
              Name: name,
              EntityName: this.entityName(),
              Filter: JSON.stringify({
                query: extracted.obj,
                parameters: extracted.parameters
              }),
              ParentId: parentId,
              CreatedOn: new Date(),
              ModifiedOn: new Date()
            }
          )
        )
      )
      .subscribe((savedFolder) => {
        this.folders.update((folders) => [
          {
            id: savedFolder.Id,
            name: savedFolder.Name,
            parentId: savedFolder.ParentId,
            filter: {
              query: extracted.obj,
              parameters: extracted.parameters
            }
          },
          ...folders
        ]);
        this.showSavedAlert().subscribe();
      });
  }

  editFolder(folder: FolderDto) {
    this.tuiDialog
      .open<EditFolderResult | null>(
        new PolymorpheusComponent(EditFolderComponent),
        {
          data: {
            folder: {
              name: folder.name,
              parentId: folder.parentId
            },
            flatFolderList: this.folders()
          }
        }
      )
      .pipe(
        filter(tuiIsPresent),
        mergeMap(({ name }) =>
          this.metastore.update('UIFolder', { Id: folder.id, Name: name })
        )
      )
      .subscribe((updatedFolder) => {
        this.folders.update((folders) => {
          const oldFolder = folders.find((f) => f.id === updatedFolder.Id);
          if (oldFolder) {
            return [
              {
                ...oldFolder,
                name: updatedFolder.Name
              },
              ...folders.filter((f) => f.id !== updatedFolder.Id)
            ];
          }
          return folders;
        });
        this.showUpdatedAlert().subscribe();
      });
  }

  updateFolderFilter(folderId: number, newFilter: object) {
    const extracted = extractObjectParams(newFilter);
    this.metastore
      .update('UIFolder', {
        Id: folderId,
        Filter: JSON.stringify({
          query: extracted.obj,
          parameters: extracted.parameters
        })
      })
      .subscribe((updatedFolder) => {
        this.folders.update((folders) => {
          const oldFolder = folders.find((f) => f.id === updatedFolder.Id);
          if (oldFolder) {
            return [
              {
                ...oldFolder,
                filter: {
                  query: extracted.obj,
                  parameters: extracted.parameters
                }
              },
              ...folders.filter((f) => f.id !== updatedFolder.Id)
            ];
          }
          return folders;
        });
        this.showUpdatedAlert().subscribe();
      });
  }

  removeFolder(folder: FolderDto) {
    this.tuiDialog
      .open<boolean>(TUI_PROMPT, {
        label: this.translate.instant('folders.removeConfirmText'),
        size: 's',
        data: {
          yes: this.translate.instant('Yes'),
          no: this.translate.instant('No')
        }
      })
      .pipe(
        filter((v) => v),
        mergeMap(() => this.metastore.delete('UIFolder', folder.id))
      )
      .subscribe(() => {
        this.folders.update((folders) => [
          ...folders.filter((f) => f.id !== folder.id)
        ]);
        this.showRemovedAlert().subscribe();
      });
  }

  copyFolder(folder: FolderDto) {
    const copyName = `${folder.name} Copy`;
    this.metastore
      .insert<{ Id: number }>('UIFolder', {
        EntityName: this.entityName(),
        Filter: JSON.stringify(folder.filter),
        Name: copyName,
        ParentId: folder.parentId,
        CreatedOn: new Date(),
        ModifiedOn: new Date()
      })
      .subscribe((copiedFolder) => {
        this.folders.update((folders) => [
          { ...folder, name: copyName, id: copiedFolder.Id },
          ...folders
        ]);
        this.showCopiedAlert().subscribe();
      });
  }

  showRemovedAlert() {
    return this.tuiAlert.open(
      this.translate.instant('folders.alert.folderRemoved'),
      {
        status: 'success'
      }
    );
  }

  showCopiedAlert() {
    return this.tuiAlert.open(
      this.translate.instant('folders.alert.folderCopied'),
      {
        status: 'success'
      }
    );
  }

  showUpdatedAlert() {
    return this.tuiAlert.open(
      this.translate.instant('folders.alert.folderUpdated'),
      {
        status: 'success'
      }
    );
  }

  showSavedAlert() {
    return this.tuiAlert.open(
      this.translate.instant('folders.alert.folderSaved'),
      {
        status: 'success'
      }
    );
  }
}
