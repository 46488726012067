import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  computed,
  signal
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TextInputModule } from '@konnektu/components';
import { DestroyService } from '@konnektu/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { TuiButtonModule, TuiScrollbarModule } from '@taiga-ui/core';
import { ExpandedFolderDto } from '../folder.service';
import { FolderComponent } from '../folder/folder.component';

@Component({
  selector: 'knk-folders-list',
  templateUrl: 'folder-list.template.html',
  styleUrls: ['folder-list.template.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    TuiButtonModule,
    TuiScrollbarModule,
    TextInputModule,
    FolderComponent
  ],
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderListComponent {
  protected readonly _isCollapsed = signal(false);

  protected readonly _selectedFolderId = signal<number | null>(null);

  protected readonly _searchInput = signal('');

  protected readonly searchInput$ = toObservable(this._searchInput);

  protected readonly openedPath = signal<number[]>([]);

  protected readonly _folders = signal<ExpandedFolderDto[]>([]);

  protected readonly currentFolders = computed(() => {
    let currentFolders = this._folders();
    const path = [...this.openedPath()];
    while (path.length) {
      const id = path.pop();
      const children = currentFolders.find((f) => f.id === id)?.children;
      if (children) {
        currentFolders = children;
      }
    }
    return currentFolders;
  });

  @Input() set isCollapsed(val: boolean) {
    this._isCollapsed.update(() => val);
  }

  @Input() set selectedFolderId(id: number | null) {
    this._selectedFolderId.update(() => id);
  }

  @Input() set folders(value: ExpandedFolderDto[]) {
    this._folders.update(() => value);
  }

  @Input() set searchInput(val: string) {
    this._searchInput.update(() => val);
  }

  @Output() searchInputChange = new EventEmitter<string>();

  @Output() folderSelect = new EventEmitter<ExpandedFolderDto>();

  @Output() folderEdit = new EventEmitter<ExpandedFolderDto>();

  @Output() folderCopy = new EventEmitter<ExpandedFolderDto>();

  @Output() folderRemove = new EventEmitter<ExpandedFolderDto>();

  selectFolder(selectedFolder: ExpandedFolderDto): void {
    this._selectedFolderId.update(() => selectedFolder.id);
    this.folderSelect.emit(selectedFolder);
  }

  toggleCollapse(): void {
    this._isCollapsed.update((collapsed) => !collapsed);
  }

  openFolder(folder: ExpandedFolderDto) {
    this.openedPath.update((path) => [...path, folder.id]);
  }

  folderBack() {
    this.openedPath.update((path) => path.slice(0, -1));
  }

  updateSearchInput(input: string) {
    this._searchInput.update(() => input);
    this.searchInputChange.emit(this._searchInput());
  }
}
