@if (currentFolders(); as folders) {
<ul [ngClass]="{ collapsed: _isCollapsed()}" class="knk-folders-list">
  <div class="knk-folders-list__header">
    <div class="knk-folders-list__header__title-row">
      <span [hidden]="_isCollapsed()" class="knk-folders-list__header__title"> {{ 'Folders' | translate }} </span>

      <button
        tuiIconButton
        size="s"
        appearance="icon"
        (click)="toggleCollapse()"
        class="knk-folders-list__header__chevron"
        [icon]="_isCollapsed() ? 'tuiIconChevronRight' : 'tuiIconChevronLeft'"
      ></button>
    </div>

    <knk-text-input
      [ngModel]="_searchInput()"
      (ngModelChange)="updateSearchInput($event)"
      [hidden]="_isCollapsed()"
      [placeholder]="'common.search' | translate"
    ></knk-text-input>
  </div>

  <tui-scrollbar>
    @if(openedPath().length) {
    <button
      class="tui-space_bottom-2 back-button"
      (click)="folderBack()"
      tuiButton
      appearance="secondary"
      size="m"
      icon="tuiIconChevronLeft"
    >
      {{ 'common.back' | translate }}
    </button>
    } @for (folder of folders; track folder.id) {
    <li>
      <knk-folder
        [folder]="folder"
        [isCollapsed]="_isCollapsed()"
        [selected]="_selectedFolderId() === folder.id"
        (folderEdit)="folderEdit.emit($event)"
        (folderRemove)="folderRemove.emit($event)"
        (folderCopy)="folderCopy.emit($event)"
        (folderSelect)="selectFolder($event)"
        (folderOpen)="openFolder($event)"
      ></knk-folder>
    </li>
    } @empty {
      @if (!_isCollapsed()) {
        <li>{{ 'common.nothing' | translate }}</li>
      }
    }
  </tui-scrollbar>
</ul>
}
