import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiSvgModule
} from '@taiga-ui/core';
import { ExpandedFolderDto } from '../folder.service';

@Component({
  selector: 'knk-folder',
  templateUrl: 'folder.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiHintModule,
    TuiDropdownModule,
    TuiHostedDropdownModule,
    TuiDataListModule,
    TuiActiveZoneModule
  ],
  styleUrls: ['folder.component.scss']
})
export class FolderComponent {
  @Input() folder!: ExpandedFolderDto;

  @Input() selected = false;

  @Input() isCollapsed = true;

  @Output() folderOpen = new EventEmitter<ExpandedFolderDto>();

  @Output() folderCopy = new EventEmitter<ExpandedFolderDto>();

  @Output() folderEdit = new EventEmitter<ExpandedFolderDto>();

  @Output() folderRemove = new EventEmitter<ExpandedFolderDto>();

  @Output() folderSelect = new EventEmitter<ExpandedFolderDto>();

  isShowMenu = false;

  onSelectFolder() {
    this.folderSelect.emit(this.folder);
  }

  onCopyFolder() {
    this.folderCopy.emit(this.folder);
    this.isShowMenu = false;
  }

  onDeleteFolder() {
    this.folderRemove.emit(this.folder);
    this.isShowMenu = false;
  }

  onEditFolder() {
    this.folderEdit.emit(this.folder);
    this.isShowMenu = false;
  }

  toggleFolder() {
    this.folderOpen.emit(this.folder);
  }

  openMenu(event: MouseEvent) {
    event.stopPropagation();
    this.isShowMenu = true;
  }

  openFolder(event: MouseEvent) {
    event.stopPropagation();
    this.folderOpen.emit(this.folder);
  }

  closeMenu(active: boolean) {
    this.isShowMenu = active && this.isShowMenu;
  }
}
