<div (click)="onSelectFolder()" [ngClass]="{ selected, collapsed: isCollapsed, dropdown: isShowMenu }" class="knk-folder">
  <tui-svg src="tuiIconFolder"></tui-svg>

  @if (!isCollapsed) {
    <div class="knk-folder__title" [tuiHint]="folder.name">
      {{ folder.name }}
    </div>

    @if (folder.children.length) {
      <button
        (click)="openFolder($event)"
        appearance="icon"
        icon="tuiIconChevronRight"
        tuiIconButton
        size="s"
        type="button"
      ></button>
    }

    <button
      [tuiDropdown]="actions"
      [tuiDropdownManual]="isShowMenu"
      (tuiActiveZoneChange)="closeMenu($event)"
      (click)="openMenu($event)"
      appearance="icon"
      class="knk-folder__menu"
      icon="tuiIconMoreVertical"
      tuiIconButton
      size="s"
      type="button"
    ></button>
  }

  <ng-template #actions>
    <tui-data-list>
      <button tuiOption (click)="onEditFolder()">
        {{ 'folders.actions.edit' | translate }}
      </button>
      <button tuiOption (click)="onCopyFolder()">
        {{ 'folders.actions.copy' | translate }}
      </button>
      <button tuiOption (click)="onDeleteFolder()">
        {{ 'folders.actions.delete' | translate }}
      </button>
    </tui-data-list>
  </ng-template>
</div>
