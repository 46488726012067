<div class="knk-section">
  <ng-container *polymorpheusOutlet="filters"> </ng-container>
  @if (isFilterVisible() && enableFilter) {
    <knk-expression-builder
      (applied)="updateFilter($event)"
      (resetClicked)="resetFilter()"
      *ngIf="isFilterVisible() && enableFilter"
      [entityName]="_entityName()"
      [expression]="_filter()"
      [isShowReset]="isFilterVisible() && enableFilter"
      [showSave]="!!_filter()"
      (saveClick)="activeFolderId() ? updateCurrentFolderFilter() : folderSave()"
      [saveHint]="(activeFolderId() ? 'section.filter.updateFolder' : 'section.filter.saveToFolder') | translate"
      [metadataProvider]="metadataProvider$"
      class="knk-section__filter"
    >
    </knk-expression-builder>
  }

  <div
    [ngClass]="{
      'knk-section__content': showFolders,
      'knk-section__content-no-folders': !showFolders
    }"
  >
    <knk-folders-list
      class="folders"
      *ngIf="showFolders"
      [folders]="(folders$ | async) ?? []"
      [selectedFolderId]="activeFolderId()"
      (folderSelect)="updateSelectedFolder($event)"
      (searchInputChange)="updateFolderSearch($event)"
      (folderEdit)="editFolder($event)"
      (folderRemove)="removeFolder($event)"
      (folderCopy)="copyFolder($event)"
    ></knk-folders-list>

    <tui-scrollbar class="scroll" infiniteScroll (scrolled)="onScrollToEnd()" [scrollWindow]="false">
      <tui-loader [overlay]="true" [showLoader]="_loading()">
        <table
          tuiTable
          size="l"
          [columns]="tuiColumns"
          [direction]="sortDirection()"
          [tuiSortBy]="sortColumn()"
          (directionChange)="updateSortDirection($event)"
          (tuiSortByChange)="updateSortColumn($any($event))"
        >
          <thead tuiThead>
            <tr tuiThGroup>
              @for (column of columns; track column.id) {
                @if (column.sortable === true) {
                  <th tuiTh *tuiHead="column.id" [sorter]="null" tuiSortable>
                    @if (isString(column.header)) {
                      {{ column.header | translate }}
                    } @else if (column.header) {
                      <ng-container [ngTemplateOutlet]="column.header"></ng-container>
                    }
                  </th>
                } @else {
                  <th tuiTh *tuiHead="column.id" [sorter]="null">
                    @if (isString(column.header)) {
                      {{ column.header | translate }}
                    } @else if (column.header) {
                      <ng-container [ngTemplateOutlet]="column.header"></ng-container>
                    }
                  </th>
                }
              }
            </tr>
          </thead>

          @if (tableData | async; as data) {
            <tbody tuiTbody [data]="data.items">
              @for (item of data.items; track $index) {
                <tr
                  [class.clickable]="entityClicked.observed || enableHover"
                  (tuiHoveredChange)="updateHoveredItem(item, $event)"
                  tuiTr
                  (click)="rowClicked(item)"
                >
                  @for (column of columns; track column.id) {
                    <td *tuiCell="column.id" tuiTd>
                      @if (column.content) {
                        <ng-container
                          [ngTemplateOutlet]="column.content"
                          [ngTemplateOutletContext]="{ $implicit: item, hoveredItem: hoveredItem() ?? undefined }"
                        ></ng-container>
                      } @else if (column.type === 'date') {
                        <div [tuiHint]="hintContent">
                          {{ $any(item)[column.id] | date: 'dd.MM.yyyy' }}
                        </div>
                        <ng-template #hintContent>
                          {{ $any(item)[column.id] | date: 'dd.MM.yyyy HH:mm:ss' }}
                        </ng-template>
                      } @else if (column.type === 'boolean') {
                        {{ ($any(item)[column.id] ? 'Yes' : 'No') | translate }}
                      } @else {
                        {{ $any(item)[column.id] }}
                      }
                    </td>
                  }
                </tr>
              }
            </tbody>
          }

          @if (enablePagination && paginationMode === 'default') {
            @if (_pagination(); as paging) {
              <tfoot>
                <tr>
                  <td [colSpan]="columns.length">
                    <tui-table-pagination
                      class="pagination"
                      [total]="(tableData | async)?.totalCount ?? 0"
                      [page]="paging.page"
                      (paginationChange)="updatePagination($event)"
                      [size]="paging.size"
                    ></tui-table-pagination>
                  </td>
                </tr>
              </tfoot>
            }
          }
        </table>
      </tui-loader>
    </tui-scrollbar>
  </div>
</div>
