import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule, ChipModule, TableModule } from '@konnektu/components';
import { TranslatePipe } from '@konnektu/helpers';
import {
  IconComponent,
  iconFilter,
  iconSave,
  provideIcons
} from '@konnektu/icons';
import { MetastoreComponentsModule } from '@konnektu/metastore';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiTableModule,
  TuiTablePaginationModule
} from '@taiga-ui/addon-table';
import { TuiHoveredModule, TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiHintModule,
  TuiLoaderModule,
  TuiScrollbarModule
} from '@taiga-ui/core';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FolderListComponent } from './folders/folder-list/folder-list.component';
import { SectionV2Component } from './section.component';
import { sectionFeature } from './state/feature';

@NgModule({
  declarations: [SectionV2Component],
  imports: [
    CommonModule,
    MetastoreComponentsModule,
    TableModule,
    ButtonModule,
    ChipModule,
    TranslatePipe,
    IconComponent,
    MatTooltipModule,
    TuiTableModule,
    TranslateModule,
    TuiLetModule,
    TuiButtonModule,
    TuiScrollbarModule,
    TuiLoaderModule,
    TuiTablePaginationModule,
    InfiniteScrollModule,
    PolymorpheusModule,
    TuiHoveredModule,
    StoreModule.forFeature(sectionFeature),
    FolderListComponent,
    TuiHintModule
  ],
  exports: [SectionV2Component],
  providers: [provideIcons([iconFilter, iconSave])]
})
export class SectionV2Module {}
