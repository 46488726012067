import { UIFolder } from '@konnektu/domain-models';
import { createAction, props } from '@ngrx/store';

export const initSection = createAction(
  '[Section] Initialize',
  props<{ id: string }>()
);
export const sectionPaginationChange = createAction(
  '[Section] Change pagination',
  props<{ id: string; pagination: { page: number; perPage: number } }>()
);

export const sectionNextPage = createAction(
  '[Section] Change scroll',
  props<{ id: string }>()
);

export const sectionOrderChange = createAction(
  '[Section] Change order',
  props<{
    id: string;
    sort: { column: string; dir: 'asc' | 'desc' } | null;
  }>()
);

export const sectionFiltersChange = createAction(
  '[Section] Change filter',
  props<{ id: string; filter: Record<string, any> | null }>()
);

export const sectionSetActiveFolder = createAction(
  '[Section] Set active folder',
  props<{
    id: string;
    folder: Omit<UIFolder, 'Filter'> & {
      Filter: Record<string, any>;
    };
  }>()
);

export const sectionResetActiveFolder = createAction(
  '[Section] Reset active folder',
  props<{ id: string }>()
);

/**
 * @deprecated this will no longer work
 */
export const sectionRefresh = createAction('[Section] Refresh');

export const sectionToggleShowFilter = createAction(
  '[Section] Toggle show filter',
  props<{ id: string }>()
);

export const sectionResetFilter = createAction(
  '[Section] Reset filter',
  props<{ id: string }>()
);
