import { injectObjectParameters } from '@konnektu/metastore';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  initSection,
  sectionFiltersChange,
  sectionNextPage,
  sectionOrderChange,
  sectionPaginationChange,
  sectionResetActiveFolder,
  sectionResetFilter,
  sectionSetActiveFolder,
  sectionToggleShowFilter
} from './actions';

export interface SectionState {
  pagination: {
    page: number;
    perPage: number;
  };
  order: {
    column: string;
    dir: 'asc' | 'desc';
  } | null;
  filter: Record<string, any> | null;
  selectedFolderId: number | null;
  showFilter: boolean;
}

export interface SectionsState {
  [id: string]: SectionState;
}

export const sectionFeature = createFeature({
  name: 'sections',
  reducer: createReducer<SectionsState>(
    {},
    on(initSection, (state, { id }) => ({
      ...state,
      ...(state[id]
        ? {}
        : {
            [id]: {
              pagination: {
                page: 0,
                perPage: 20
              },
              order: null,
              filter: null,
              selectedFolderId: null,
              showFilter: true
            }
          })
    })),
    on(sectionPaginationChange, (state, { id, pagination }) => ({
      ...state,
      [id]: {
        ...state[id],
        pagination
      }
    })),
    on(sectionNextPage, (state, { id }) => ({
      ...state,
      [id]: {
        ...state[id],
        pagination: {
          ...state[id].pagination,
          page: state[id].pagination.page + 1
        }
      }
    })),
    on(sectionOrderChange, (state, { id, sort }) => ({
      ...state,
      [id]: {
        ...state[id],
        order: sort
      }
    })),
    on(sectionFiltersChange, (state, { id, filter }) => ({
      ...state,
      [id]: {
        ...state[id],
        filter
      }
    })),
    on(sectionSetActiveFolder, (s, { id, folder }) => {
      const filter = injectObjectParameters(
        folder.Filter['expression'] || folder.Filter['query'],
        folder.Filter['parameters']
      );
      return {
        ...s,
        [id]: {
          ...s[id],
          selectedFolderId: folder.Id,
          filter: filter
        }
      };
    }),
    on(sectionResetActiveFolder, (s, { id }) => ({
      ...s,
      [id]: {
        ...s[id],
        selectedFolderId: null,
        filter: null
      }
    })),
    on(sectionToggleShowFilter, (s, { id }) => ({
      ...s,
      [id]: {
        ...s[id],
        showFilter: !s[id].showFilter
      }
    })),
    on(sectionResetFilter, (s, { id }) => ({
      ...s,
      [id]: {
        ...s[id],
        filter: null
      }
    }))
  )
});
